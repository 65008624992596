import React, { useEffect, useRef, useState } from "react";
import "./Contact.css";
// import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { BiSolidPhone } from "react-icons/bi";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { CgMail } from "react-icons/cg";
import { Link } from "react-router-dom";

function Contact() {
  const form = useRef();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert("Please complete the reCAPTCHA.");
      return;
    }

    //   emailjs
    //     .sendForm(
    //       "service_sejiz7g",
    //       "template_dhn3as6",
    //       form.current,
    //       "xtfIvOIg5k7aeWJJF"
    //     )
    //     .then(
    //       (result) => {
    //         console.log("SUCCESS!", result.text);
    //       },
    //       (error) => {
    //         console.log("FAILED...", error.text);
    //       }
    //     );

    //   e.target.reset();
    setRecaptchaToken(null);
  };

  const inputs = [
    {
      type: "text",
      value: fullName,
      name: "user_name",
      onchange: setFullName,
      title: "Full Name",
      placeholder: "Enter Full Name here",
      required: true,
    },
    {
      type: "email",
      value: email,
      name: "user_email",
      onchange: setEmail,
      title: "Email",
      placeholder: "Enter Email here",
      required: true,
    },
    {
      type: "text",
      value: contact,
      name: "user_contact",
      onchange: setContact,
      title: "Phone Number",
      placeholder: "Enter Phone Number here",
      required: true,
    },
    {
      type: "text",
      value: address,
      name: "user_address",
      onchange: setAddress,
      title: "Street Address",
      placeholder: "Enter Street Address here",
      required: true,
    },
    {
      type: "text",
      value: postcode,
      name: "user_postcode",
      onchange: setPostcode,
      title: "Postcode",
      placeholder: "Enter Postcode here",
      required: true,
    },
    {
      type: "select",
      value: service,
      name: "user_service",
      options: [
        "Architecture Enquiry",
        "Construction Quote",
        "Design & Build Package",
      ],
      onchange: setService,
      title: "Services",
      placeholder: "Select Service",
      required: true,
    },
    {
      type: "textarea",
      value: message,
      name: "user_message",
      onchange: setMessage,
      title: "How can we help you?",
      placeholder: "Enter your message here",
      required: false,
    },
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "145037993",
          formId: "aca85b17-46d4-4ee7-b24c-6a6f619df1d9",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <div className="contact-container">
      <div className="contact-top-container">
        <h1>CONTACT US</h1>
        <p className="contact-sub-sub">
          Need assistance or further information? Reach out to us via details
          below and our Team will be happy to help with your project needs.
        </p>
      </div>
      <div className="contact-bottom-container">
        <div className="contact-bottom-left-container">
          <div className="contact-left-intro-div">
            <h1>
              GET A <span className="contact-free">FREE</span> QUOTE
            </h1>
            <div className="mobile-screen-contact-details">
              <p>Call Us -- 024 7772 1468</p>
              <p>Email Us -- info@pacltd.co.uk</p>
            </div>
            <p>
              Please fill out the enquiry form below and we will get back to you
              via our landline & email.
            </p>
          </div>
          <div className="contact-left-form-div">
            <form
              id="hubspotForm"
              className="form"
              onSubmit={handleSubmit}
              ref={form}
            >
              <div className="input">
                {inputs.map((item, index) => (
                  <div className="field" key={index}>
                    <label>{item.title}</label>
                    {item.type === "textarea" ? (
                      <textarea
                        name={item.name}
                        value={item.value}
                        placeholder={item.placeholder}
                        onChange={(e) => item.onchange(e.target.value)}
                        required={item.required}
                      />
                    ) : item.type === "select" ? (
                      <select
                        name={item.name}
                        value={item.value}
                        onChange={(e) => item.onchange(e.target.value)}
                        required={item.required}
                      >
                        <option value="" disabled>
                          {item.placeholder}
                        </option>
                        {item.options.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        name={item.name}
                        type={item.type}
                        value={item.value}
                        placeholder={item.placeholder}
                        onChange={(e) => item.onchange(e.target.value)}
                        required={item.required}
                      />
                    )}
                  </div>
                ))}
              </div>
              <ReCAPTCHA
                sitekey="6LdIqB4qAAAAAKhK6VBnToG604tjKyeb0yxGSruK"
                onChange={handleRecaptchaChange}
              />
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
        <div className="contact-bottom-right-container">
          <div className="contact-us-email-phone">
            <div className="contact-us-details-container">
              <div className="contact-us-details">
                <div className="contact-heading">
                  <span>
                    <BiSolidPhone />
                  </span>
                  <p>Call us</p>
                </div>
                <p>024 7772 1468</p>
              </div>
              <div className="contact-us-details">
                <div className="contact-heading">
                  <span>
                    <CgMail />
                  </span>
                  <p>Email us</p>
                </div>
                <p>info@pacltd.co.uk</p>
              </div>
            </div>
            <div className="contact-other-ways">
              <h1>Other Ways To Connect</h1>
              <div className="contact-socials-div">
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=61561859695503"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/paragon.a.c?igsh=dGUzZ3Z6eGt3ZnRm"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/paragon-architecture-construction-uk"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </div>
          <div className="contact-tps-btn-container">
            <p>
              During Design & Planning stages, you may need to enquire for
              additional services that are{" "}
              <span className="font-bold">NOT</span> included within our scope
              of work. Click here to find out all relevant Third Parties.
            </p>
            <div className="contact-tps-btn">
              <Link to="/third-party-services" className="contact-tps-btn-con">
                Third Party Services
              </Link>
            </div>
          </div>
          <div className="contact-area">
            <h1>Areas We Cover</h1>
            <p>
              Paragon specialises in projects within the areas highlighted
              below, ensuring exceptional service in these regions and expertise
              in with these local councils. We also welcome & consider enquiries
              from locations beyond this scope. Our team is dedicated to
              providing a streamline service, regardless of project location. We
              are committed to assessing and accommodating your needs wherever
              you are based.
            </p>
            <div className="map">
              <img src="/assets/images/map.png" alt="contact-map" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
