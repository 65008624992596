import React from "react";
import "./Aboutus.css";
import { motion } from "framer-motion";
import ContactBtn from "../components/ContactBtn";

function Aboutus() {
  const data = [
    {
      title: "YAS",
      class: "member-1",
      url: "",
      designation: "Principal Construction Manager",
      description:
        "Driven by a passion for building excellence, Yas manages projects from inception to completion, ensuring they are finished on schedule, within budget, and to the highest quality standards. He is dedicated to maintaining effective communication throughout all construction projects",
    },
    {
      title: "MIKE",
      class: "member-2",
      url: "https://www.linkedin.com/in/mikecanouk/",
      designation: "Chartered Consultant",
      description:
        "With experience in over 800 residential projects over the past 10 years, and a passion for creative designs, Mike is a Chartered Architect dedicated to working closely with you to maximise your project's potential beyond your expectations to bring your dreams to life.",
    },
    {
      title: "THAO",
      class: "member-3",
      url: "https://www.linkedin.com/in/thaocostauk/",
      designation: "Chief Operating Officer",
      description:
        "Equipped with over 10 years expertise in customer centricity, Thao is committed to personalising every project journey to your needs. She ensures immense effort for a seamless experience with Paragon at every stage of Design & Build, especially intermediary requests",
    },
  ];

  return (
    <motion.div className="aboutus-container mb-5">
      <div className="about-container">
        <div className="about-left">
          <div className="about-left-img-con">
            <img
              src="/assets/images/about-final-img.jpg"
              alt="aboutus"
              className="about-img"
            />
          </div>
        </div>
        <div className="about-right">
          <div className="about-right-div">
            <h1>ABOUT</h1>
            <p className="head">
              Experience the Paragon difference - where exceptional design meets
              unparalleled build.
            </p>
            <p className="about-para font-light">
              We believe that your home should be a true reflection of your
              personality and lifestyle. With meticulous attention to detail and
              an unwavering focus on customer satisfaction, Paragon Architecture
              and Construction ensures that every project we undertake is
              tailored to meet your unique needs and desires.
            </p>
            <p className="about-para">
              Our comprehensive approach encompasses all aspects of the design
              and construction process. From the initial concept to the final
              build, we work closely with you to create a harmonious blend of
              aesthetics and functionality. Our experienced Architects and
              Construction experts collaborate seamlessly to deliver your
              project in a timely manner to the highest standards.
            </p>
          </div>
        </div>
      </div>

      <div className="specialists">
        <h1>OUR SPECIALISTS</h1>
        <div className="specialists-div">
          {data.map((item, index) => (
            <div div className="member-card-con">
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                className={`member-cards ${item.class}`}
              >
                <div className="member-details">
                  <p>{item.title}</p>
                </div>
              </a>
              <div className="">
                <p className="designation">{item.designation}</p>
                <p className="description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="about-contact-btn">
        <ContactBtn justify="center" />
      </div>
    </motion.div>
  );
}

export default Aboutus;
