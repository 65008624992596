import React from "react";
import OurServices from "./OurServices";
import Intro2 from "./Intro2";
import Section2 from "./Section2";

function Home() {
  return (
    <div className="w-full h-full m-0 p-0">
      <Intro2 />
      <OurServices />
      <Section2 />
    </div>
  );
}

export default Home;
