import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import "./Projects.css";

const images = [
  "/assets/images/gallery/gallery-1-1.jpg",
  "/assets/images/gallery/gallery-1-2.jpg",
  "/assets/images/gallery/gallery-1-3.jpg",
  "/assets/images/gallery/gallery-2-1.jpg",
  "/assets/images/gallery/gallery-2-2.jpg",
  "/assets/images/gallery/gallery-2-3.jpg",
  "/assets/images/gallery/gallery-3-2.jpg",
  "/assets/images/gallery/gallery-3-1.jpg",
  "/assets/images/gallery/gallery-3-3.jpg",
  "/assets/images/gallery/gallery-4-1.jpg",
  "/assets/images/gallery/gallery-4-2.jpg",
  "/assets/images/gallery/gallery-4-3.jpg",
  "/assets/images/gallery/gallery-5-1.jpeg",
  "/assets/images/gallery/gallery-5-2.jpeg",
  "/assets/images/gallery/gallery-5-3.jpeg",
  "/assets/images/gallery/gallery-6-1.jpeg",
  "/assets/images/gallery/gallery-6-2.jpeg",
  "/assets/images/gallery/gallery-6-3.jpeg",
  "/assets/images/gallery/gallery-7-1.jpeg",
  "/assets/images/gallery/gallery-7-2.jpeg",
  "/assets/images/gallery/gallery-7-3.jpeg",
];

function Projects() {
  const [data, setData] = useState({ image: "", i: 0 });

  const viewImage = (image, i) => {
    setData({ image, i });
  };

  const imageAction = (action) => {
    let i = data.i;
    if (action === "next" && i < images.length - 1) {
      setData({ image: images[i + 1], i: i + 1 });
    }
    if (action === "prev" && i > 0) {
      setData({ image: images[i - 1], i: i - 1 });
    }
    if (!action) {
      setData({ image: "", i: 0 });
    }
  };

  return (
    <>
      {data.image && (
        <div className="w-full h-full bg-[#002F44] bg-opacity-80 fixed flex justify-center items-center overflow-hidden space-x-3">
          <CgClose
            onClick={() => imageAction()}
            className="absolute top-3 right-5 text-white font-semibold text-[25px] cursor-pointer"
          />
          <FontAwesomeIcon
            icon={faAngleDoubleLeft}
            onClick={() => imageAction("prev")}
            className="text-white text-[25px] p-2 cursor-pointer hover:scale-125 transition-transform duration-300"
          />
          <div className="w-[90%] flex justify-center">
            <img
              src={`${data.image}`}
              alt="img"
              className="w-auto max-w-[90%] max-h-[90%] mb-20"
            />
          </div>

          <FontAwesomeIcon
            icon={faAngleDoubleRight}
            onClick={() => imageAction("next")}
            className="text-white text-[25px]  p-2  cursor-pointer hover:scale-125 transition-transform duration-300"
          />
        </div>
      )}
      <div className="project-container">
        <h1>PROJECT GALLERY</h1>
        <div className="text-div">
          <p>
            The Paragon Team has been privileged to complete the following
            projects for our customers. We welcome you to reference any of these
            during your enquiry with us for your Architectural and Construction
            visions.
          </p>
        </div>

        <div className="gallery-grid">
          {images.map((image, i) => (
            <div
              key={i}
              className="bg-box"
              style={{ backgroundImage: `url(${image})` }}
              onClick={() => viewImage(image, i)}
            ></div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Projects;
