import React, { useEffect } from "react";
import "./Services.css";
import { MdOutlineArchitecture } from "react-icons/md";
import { PiBuildingsLight } from "react-icons/pi";
import { BsBricks } from "react-icons/bs";
import Timeline from "../../components/Timeline";
import ContactBtn from "../../components/ContactBtn";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Services = () => {
  const location = useLocation();

  const service = [
    {
      title: "ARCHITECTURE",
      icon: <MdOutlineArchitecture />,
      services: [
        {
          subtitle: "Consultancy",
          className: "arch-service-1",
          description:
            "We offer personalised consultations to help you visualise your project potential while ensuring compliance with local regulations.",
        },
        {
          subtitle: "Surveys",
          className: "arch-service-2",
          description:
            "Our services incorporate an expert surveyor to guarantee precision and accuracy throughout the design stage of your project.",
        },
        {
          subtitle: "Full Drawings",
          className: "arch-service-4",
          description:
            "Our skilled drafters produce existing and proposed floor plans, elevations, and detailed drawings to help visualise your project.",
        },
        {
          subtitle: "Planning Permissions",
          className: "arch-service-3",
          description:
            "If planning permission is required, we complete the forms for you and liaise with the local authority at no extra charge.",
        },
        {
          subtitle: "Building Regulations",
          className: "arch-service-5",
          description:
            "We prepare detailed project drawings for building control submission, ensuring compliance with British Building Standards.",
        },
      ],
    },
    {
      title: "CONSTRUCTION",
      icon: <BsBricks />,
      services: [
        {
          subtitle: "Extensions",
          className: "cons-service-1",
          description:
            "We provide space enhancement services with expert design & seamless integration, making your home or business more functional and spacious.",
        },
        {
          subtitle: "Refurbishments",
          className: "cons-service-2",
          description:
            "Our team revitalises your space with modern updates and thoughtful design, transforming it into a refreshed contemporary environment.",
        },
        {
          subtitle: "Conversions",
          className: "cons-service-3",
          description:
            "We expertly reconfigure existing spaces to meet new needs, turning under-utilised areas into functional floor space in your home.",
        },
        {
          subtitle: "New Builds",
          className: "cons-service-4",
          description:
            "Our specialty in custom-designed, high-quality constructions from the ground up are tailored to your specific needs & vision.",
        },
      ],
    },
    {
      title: "DESIGN & BUILD",
      icon: <PiBuildingsLight />,
    },
  ];

  useEffect(() => {
    const hash = new URLSearchParams(location.search).get("hash");
    if (hash) {
      const section = document.getElementById(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="m-0 p-0 pb-5">
      <div className="service-container">
        <div className="service-intro">
          <div className="service-intro-left">
            <div className="service-main-title">
              <h1>OUR SERVICES</h1>
            </div>
            <p className="text-[15px] w-[82%] mt-5 text-justify text-white font-thin">
              We offer comprehensive Architecture & Construction solutions.
              Click below to see more information.
            </p>
            <p className="text-[15px] w-[80%] mt-3 text-justify text-white font-thin">
              Contact us to find out more
              <span className="pl-2 font-bold">024 7772 1468</span>
            </p>
            <div className="services-sub">
              {service.map((data, index) => {
                const sectionId =
                  data.title === "DESIGN & BUILD"
                    ? "design-build"
                    : data.title.toLowerCase().replace(/\s+/g, "-");

                return (
                  <div
                    className="service-sub-box"
                    key={index}
                    onClick={() =>
                      document
                        .getElementById(sectionId)
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    <span className="text-[35px]">{data.icon}</span>
                    <p className="conthrax text-[20px] pt-1">{data.title}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="service-intro-right">
            <img src="/assets/images/service-itro-2-final.png" alt="itro" />
          </div>
        </div>
      </div>

      {/* Architecture services */}
      <div id="architecture" className="architecture">
        <div className="flex items-center justify-center">
          <div className="arch-serv-heading">
            <h1 className="conthrax text-[#002F44] text-[35px]">
              ARCHITECTURAL SERVICES
            </h1>
          </div>
        </div>
        <div className="arch-services-grid-1">
          {service[0].services.map((item, index) => (
            <div
              className={`mb-20 ${item.className} arch-service-card`}
              key={index}
            >
              <div className="arch-service-box">
                <h1>{item.subtitle}</h1>
                <p className="w-[80%] font-thin text-[15px] mt-3">
                  {item.description}
                </p>
              </div>
              <p className="arch-service-subtitle">{item.subtitle}</p>
            </div>
          ))}
        </div>
        <div className="design-timeline">
          <h1 className="conthrax text-[#002F44] text-[30px] mb-3">
            DESIGN STAGES
          </h1>
          <Timeline type="architecture" />
        </div>

        <div className="cons-free-quote -mt-28">
          <div className="cons-left">
            <p className="arch-free-quote-text-size mt-5 text-[#002F44]">
              Unlock Your Vision & Get a
              <span className=" flex flex-row">
                <p className="arch-free-quote-text-size pr-2 font-bold">FREE</p>
                <p className="arch-free-quote-text-size">Quote Today</p>
              </span>
            </p>
            <p className="arch-text-para">
              <span className="text-[#002F44] mb-0">
                Book and appointment with our Chartered Consultant to kick-start
                your project
              </span>
              <br /> At Paragon, we believe in complete transparency. Our
              Architectural services are set fees and no hidden costs. For more
              information click below.
            </p>
            <div className="flex flex-row text-[#002F44] space-x-3 pt-3">
              <ContactBtn />
              <p className="-mt-1">
                For a <b>FREE QUOTE</b>
              </p>
            </div>
          </div>
          <div className="cons-right">
            <div className="cons-right-img archimage-2">
              <div className="cons-right-details">
                <p>MIKE</p>
                <p>Principal Architect</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tps-btn-container">
        <p>
          During Design & Planning stages, you may need to enquire for
          additional services that are <span className="font-bold">NOT</span>{" "}
          included within our scope of work. Click here to find out all relevant
          Third Parties.
        </p>
        <div className="tps-btn">
          <Link to="/third-party-services" className="tps-btn-con">
            Third Party Services
          </Link>
        </div>
      </div>

      {/* Construction Services */}
      <div id="construction" className="construction">
        <div className="flex items-center justify-center">
          <div className="arch-serv-heading">
            <h1 className="conthrax text-white text-[35px]">
              CONSTRUCTION SERVICES
            </h1>
          </div>
        </div>
        <div className="arch-services-grid">
          {service[1].services.map((item, index) => (
            <div
              className={`h-64 mb-20 ${item.className} arch-service-card`}
              key={index}
            >
              <div className="arch-service-box">
                <h1>{item.subtitle}</h1>
                <p className="w-[80%] font-thin flex justify-evenly text-[15px] mt-3">
                  {item.description}
                </p>
              </div>
              <p className="arch-service-subtitle">{item.subtitle}</p>
            </div>
          ))}
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="build-stages">BUILD STAGES</p>
          <Timeline type="construction" />
        </div>

        <p className="cons-heading justify-center text-white flex flex-row">
          <p className="arch-free-quote-text-size pr-3 conthrax">
            Get a{" "}
            <span className="font-bold conthrax text-[#F8CB86]">FREE</span>{" "}
            quote to build your Dream Project
          </p>
        </p>
        <div className="cons-free-quote">
          <div className="cons-left">
            <p className="cons-text-para">
              <span className="text-white mb-7">
                Book an appointment with our Principal Construction Manager to
                discuss your build requirements
              </span>
              <br /> In order to provide you with an accurate quote, you will
              need to provide Architectural drawings. If you do not have them,
              you can request a quote from our Architecture department. <br />
              When you have approved the quote, you can secure your preferred
              start date with a 5% deposit, and pay your project in stages as we
              build it. For more information click below.
            </p>

            <div className="flex flex-row text-[#002F44] space-x-5 pt-10 pb-10">
              <ContactBtn />
              <p className="-mt-1 text-white">
                For a <b>FREE QUOTE</b>
              </p>
            </div>
          </div>
          <div className="cons-right">
            <div className="cons-right-img  consimage-1">
              <div className="cons-right-details">
                <p>YAS</p>
                <p>Principal Construction Manager</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DESIGN & BUILD */}
      <p className="design-n-build" id="design-build">
        DESIGN & BUILD
      </p>

      <div className="dnb-container">
        <div className="dnb-left">
          <div className="img-con">
            <div className="back-img">
              <img
                src="/assets/images/dnb.jpg"
                alt="dnb"
                className="back-img"
              />
            </div>
          </div>
        </div>
        <div className="dnb-right">
          <div className="dnb-sub">
            <h1 className="dnb-head">
              Experience our seamless end-to-end Project Delivery services
            </h1>
            <p className="flex flex-col">
              <span className="dnb-sub-head">
                Benefits of a Single Provider
              </span>
              Starting your project with Paragon means you have a single point
              of contact from design to completion. This approach eliminates the
              hassle of coordinating between different service providers. We
              take care of your project from the first sketch to the last brick
              being built. Enjoy a cohesive process where all your needs are
              managed under one roof.
            </p>
            <p className="flex flex-col mt-[44px]">
              <span className="dnb-sub-head">Package Discount</span>
              Pay your Architectural fees in full to complete the Planning
              phase. If you choose to proceed with the Construction quote
              tailored by our Paragon Team, we will refund full Architectural
              fees. This ensures you receive the best value & continuity in your
              project.
            </p>
          </div>
        </div>
      </div>
      <ContactBtn justify="center" text="FREE Quote" />
    </div>
  );
};

export default Services;
